import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { InternalLink } from '@mdc/ui';
import Seo from '../../components/seo';
import Card from './Card';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import './LandingPage.scss';

const PageHeader = () => {
    return (
        <header className='header'>
            <div className='betaLogo'></div>
            <div className='headerLinks'>
                <InternalLink to='/licensing'>{'Licensing'}</InternalLink>
                <a href='/login' className='signInButton'>
                    <i className='userIcon'></i>Sign In
                </a>
            </div>
        </header>
    );
};

const PageFooter = () => {
    const { t } = useTranslation();
    return (
        <footer className='pageFooter'>
            <div className='footerIcons'>
                <OutboundLink href='https://x.com/opswat' target='_blank' className='icon icon-x' aria-label='x icon' />
                <OutboundLink href='https://www.facebook.com/OPSWAT' target='_blank' className='icon icon-facebook' aria-label='facebook icon' />
                <OutboundLink href='https://www.youtube.com/user/opswat1' target='_blank' className='icon icon-youtube' aria-label='youtube icon' />
                <OutboundLink href='https://www.linkedin.com/company/opswat/' target='_blank' className='icon icon-linkedin' aria-label='linkedin icon' />
            </div>
            <div className='footerLinks'>
                <OutboundLink href='https://docs.opswat.com/mdcloud' target='_blank'> Api Docs</OutboundLink>
                <InternalLink to='/store'>{t('Store')}</InternalLink>
                <InternalLink to='/licensing'>{t('Licensing')}</InternalLink>
            </div>
        </footer >
    );
};

const LandingPage = () => {
    const COMMUNITY_SERVER_FQDN = 'https://community-qa.opswat.com';
    return (
        <div className='landingPage'>
            <meta name="description" content="Metadefender advanced solution for multiscanning"></meta>
            <Seo title="Landing page" theme="city" />
            <Container>
                <PageHeader />
            </Container>
            <div className='contentContainer'>
                <Container>
                    <div className='contentRow'>
                        <div className='content'>
                            <div className='subtitle'>Cloud-based Cybersecurity Platform</div>
                            <div className='title'>Is your digital world protected?</div>
                            <div className='description'>
                                File-based attacks continue to be the most used method of penetrating organizations. Our “Trust no file” philosophy led us to create MetaDefender Cloud, a leading cybersecurity platform designed to protect your digital world. Choose the MetaDefender Cloud option that best suits your needs and experience unmatched cybersecurity protection. Get started today and secure your digital environment with OPSWAT!
                            </div>
                        </div>
                        <div className='cards'>
                            <Card
                                imageClass='community'
                                iconClass='icon-multiscan'
                                subtitle='TRY IT FOR FREE!'
                                title='MetaDefender Cloud Community'
                                description='Explore advanced cybersecurity at no cost!
Try out OPSWAT`s leading technologies, such as Deep Content Disarm and Reconstruction (Deep CDR), DLP, Multiscanning and Sandbox analysis.'
                                buttonText='Process Your File'
                                buttonHref={COMMUNITY_SERVER_FQDN}
                            />
                            <Card
                                imageClass='comercial'
                                iconClass='icon-benefits'
                                subtitle='SECURE YOUR BUSINESS!'
                                title='MetaDefender Cloud Commercial'
                                description='Comprehensive Security for Your Business
Get full access to OPSWAT’s entire suite of advanced cybersecurity tools. Experience powerful, scalable solutions and secure your business today.'
                                buttonText='Sign In To Your Account'
                                buttonHref='/login'

                            />

                        </div>
                    </div>
                </Container>
            </div>
            <div className='background'>
                <Container>
                    <PageFooter />
                </Container>
            </div>
        </div>
    );
};

export default LandingPage;

